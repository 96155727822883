#shopAdminModal {
  border-radius: 12px;
  margin: 0px 16px;
  border: 1px solid white;
  max-width: 90vw;
  .shopAdminModal_header {
    padding: 12px;
  }
  .p-datatable-wrapper {
    max-height: 600px;
  }
}
